import React, { Component } from "react";
import { List, Card } from "antd";
import {
  BookOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  ContactsOutlined,
  MessageOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const data = [
  {
    title: (
      <span>
        <CalendarOutlined /> Week View Calendar
      </span>
    ),
    content:
      "The calendar has a week view set where you can create and edit entries",
    url: "/dayviewer.com",
  },
  {
    title: (
      <span>
        <MessageOutlined /> Fast Planner
      </span>
    ),
    content:
      "Fast planner is a drag and drop planner where you can create templates of entries and move them into the day you need to schedule it",
  },
  {
    title: (
      <span>
        <CheckSquareOutlined /> Recur Tasks
      </span>
    ),
    content: "Recur events, tasks and payment reminders weekly as needed",
  },
];

class WeekPlannerFeatureGrid extends Component {
  render() {
    return (
      <Card className="feature-grid-background" bordered={false}>
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 3,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.title}
                // bordered={false}
                className="feature-grid-cards"
              >
                {item.content}{" "}
                {/* <p>
                  <a href={item.url}>Screencast</a>
                </p> */}
              </Card>
            </List.Item>
          )}
        />
      </Card>
    );
  }
}

export default WeekPlannerFeatureGrid;
