import React from "react";
import { Link } from "gatsby";
import {
  Layout,
  Row,
  Col,
  BackTop,
  Button,
  Popover,
  Divider,
  Modal,
  Image,
} from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import LandingTeamSlider from "../../components/landingPages/landingTeamSlider";
import WeekPlannerFeatureGrid from "../../components/landingPages/weekPlannerFeatureGrid";
import { TeamPlanListForPopUp } from "../../components/plans/plans";

const { Content } = Layout;

class WeekLanding extends React.Component {
  state = {
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
  };
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }

  setModal3Visible(modal3Visible) {
    this.setState({ modal3Visible });
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="DayViewer Online Week Planner - Plan Work Weekly with an Easy To Use Weekly Work Planner built right into DayViewer Online Planner system"
          />
          <title>Weekly Planner &amp; Calendar</title>
        </Helmet>
        <Layout>
          <CommonHeader />
        </Layout>
        <Layout style={{ minHeight: "100vh" }}>
          <BackTop />

          <Content className="homepage-background">
            <Row>
              <div className="homepage-banner">
                <Col lg={12} xs={24}>
                  <div className="homepage-jumbotron">
                    <div className="homepage-headlines">
                      <h1>Online Weekly Planner &amp; Calendar</h1>
                      <h2>
                        Plan Your Week For Better Time Management Using
                        DayViewer Calendar Systems
                      </h2>

                      <div style={{ paddingTop: 20 }}>
                        <p>
                          DayViewer makes it easier to plan weekly with the
                          Online Calendar &amp; Fast Planner system. Check out
                          the short video of our weekly planner system which
                          helps you manage everything you need to do daily,
                          weekly or monthly.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button className="homepage-signup-btn">
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://app.dayviewer.com/auth/signup",
                            "_self"
                          )
                        }
                      >
                        Start Weekly Planning Today
                      </a>
                    </Button>
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  {/* <LandingTeamSlider /> */}
                  <video
                    preload="none"
                    controls="controls"
                    poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                    muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="Online Weekly Planner"
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/FastPlannerAd-final.mp4"
                  />
                </Col>
              </div>
            </Row>

            <Row>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "5em" }}
              >
                <Col lg={12} xs={24}>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Image
                      // className="centered-and-cropped position-centerquarter-lefttop"
                      // width="1200"
                      // height="600"
                      src="https://dayviewer.s3.us-west-2.amazonaws.com/img/home/dvnext/features/dv-calendar-week-view.png"
                      //src="https://via.placeholder.com/150"
                      alt="week planner"
                      title="week planner"
                      height="100%"
                      width="100%"
                    />
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div style={{ padding: 20 }}>
                    <div>
                      <h1>Online Weekly Planner</h1>
                      <h2>
                        Stay In Control Of Your Week With DayViewer Week Planner
                      </h2>
                      <p>
                        It's likely that you have a lot of similar tasks,
                        procedures and events happening every week. Such as a
                        fitness routine, meals, project reports. With DayViewer,
                        you can create recurring or template entries - this will
                        help you efficiently structure your time throughout the
                        week.
                      </p>
                      <p>
                        Planning every week helps keep you motivated and on
                        track to hit your goals or targets
                      </p>
                      <p>
                        Simple to pick up and use - designed to be uncomplicated
                        to setup and use, requiring no IT expertise or 3rd party
                        connecting.
                      </p>
                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Sign Up - Free Trial
                          </a>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <div
                className="homepage-jumbotron"
                style={{ textAlign: "left", padding: "5em" }}
              >
                <Row>
                  <Col lg={12} xs={0}>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <h2>How To Plan Your Week</h2>
                      <div style={{ paddingRight: 20 }}>
                        <ul>
                          <li>
                            Set some time out where you can focus on planning in
                            advance of the upcoming week. The evening before the
                            new week begins is a good time.
                          </li>
                          <li>
                            Make a list of everything you really wish to get
                            done during that week. You don't need to think too
                            long term - the focus is on your upcoming week.
                          </li>
                          <li>
                            If you have things you would likely plan on other
                            weeks also, create a template of that task or entry
                            within your DayViewer Fast Planner and push into the
                            days you need. Or create the initial entry and recur
                            it weekly (the recur method is better if the time
                            and details of the event are regular).
                          </li>
                          <li>
                            Now you have a few entries in your calendar. You can
                            use the fast planner as a weekly calendar planner
                            (as it shows the current week by default) - Or you
                            can switch to the weekly view, (or work week view)
                            of your main calendar.
                          </li>
                          <li>
                            Plan around the core events of your week, but
                            remember to leave some buffer time when things are a
                            bit too close together in case of task overruns.
                          </li>
                        </ul>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Plan Your Week Today - Sign Up
                          </a>
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={24}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <a onClick={() => this.setModal1Visible(true)}>
                        <img
                          // className="centered-and-cropped position-centerquarter-lefttop"
                          // width="1200"
                          // height="600"
                          src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                          alt="team labels organizer"
                          title="team labels organizer"
                        />
                      </a>
                      <Modal
                        centered
                        visible={this.state.modal1Visible}
                        onOk={() => this.setModal1Visible(false)}
                        onCancel={() => this.setModal1Visible(false)}
                        width="90%"
                      >
                        <img src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png" />
                      </Modal>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <Row>
              <WeekPlannerFeatureGrid />
            </Row>
          </Content>
        </Layout>
        <Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default WeekLanding;
